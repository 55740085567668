// import siteLogo from "../assets/images/site-logo.png";
import comlinkmobile from "../assets/images/comlink-mobile.svg";
import comlinktv from "../assets/images/comlink-TV.png";
import comlinkpartner from "../assets/images/comlink-partner-program.svg";
import siteLogo from "../assets/images/For-desktop-and-laptop/logo.png";
// import mailLogo from "../assets/images/For-desktop-and-laptop/E-mail icon.png";
import Link from "../assets/images/link.png";
import leadprovider0 from "../assets/images/lead-provider-0.png";
import leadprovider1 from "../assets/images/lead-provider-1.png";
import leadprovider2 from "../assets/images/lead-provider-2.png";
import leadprovider3 from "../assets/images/lead-provider-3.png";
import leadprovider4 from "../assets/images/lead-provider-4.png";
import videoposter from "../assets/images/vide-poster.png";
import sport1 from "../assets/images/sport-1.png";
import sport2 from "../assets/images/sport-2.png";
import sport3 from "../assets/images/sport-3.png";
import sport4 from "../assets/images/sport-4.png";
import sport5 from "../assets/images/sport-5.png";
import sport6 from "../assets/images/sport-6.png";
import sport7 from "../assets/images/sport-7.png";
import zipicon from "../assets/images/zip-icon.png";
import map from "../assets/images/map-imag.png";
import footerLogo from "../assets/images/comlink-bundle-white.png";
import comlinkPartnerProgram from "../assets/images/comlink-partner.svg";
import comlinkMobility from "../assets/images/comlink-bundle.png";
import optmimumWhiteLogo from "../assets/images/optmimumWhiteLogo.png";
import optmimumblackLogo from "../assets/images/opt-logo.png";
import bluemobile from "../assets/images/blue-mobile.png";
import spvisa from "../assets/images/sp-visa.png";


// import comlinkTV from "../assets/images/comlinkTv.png";


export const Images = {
    siteLogo,
    comlinkmobile,
    comlinktv,
    comlinkpartner,
    footerLogo,
    Link,
    leadprovider0,
    leadprovider1,
    leadprovider2,
    leadprovider3,
    leadprovider4,
    videoposter,
    sport1,
    sport2,
    sport3,
    sport4,
    sport5,
    sport6,
    sport7,
    zipicon,
    map,
    comlinkPartnerProgram,
    comlinkMobility,
    // comlinkTV,
    optmimumWhiteLogo,
    optmimumblackLogo,
    bluemobile,
    spvisa,
};
