import React, { useState } from 'react'
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import "./Banner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import SearchForm from '../../Common/SearchForm/SearchForm';
import Loader from '../../Common/Loader/Loader';
import cash from "../../../../assets/images/For-desktop-and-laptop/Cash-app.png";
import venmo from "../../../../assets/images/For-desktop-and-laptop/Venmo.png"
import comlink from "../../../../assets/images/dashboardSponsored.png"
import { Link, useNavigate } from 'react-router-dom';

function Banner({ setRegistrationType, scrollToSection, section1Ref }) {
    let navigate = useNavigate();
    let link = "https://www.nationalsportsid.com/florida-flag-football/product/state-tournament-fort-myers/"
    let link2 = "https://www.zortssports.com/join/tournament/16151"
    let link3 = "https://www.zortssports.com/join/tournament/17028"

    const handleYouthRoute = () => {
        setRegistrationType("youth")
        window.open(link, "_blank");
    }
    const handleYouthRouteZorts = () => {
        setRegistrationType("youth")
        window.open(link2, "_blank");
    }

    const handleAdultForm = () => {
        setRegistrationType("adult")
        navigate("/adult-registration")
    }

    const handleSponsor = () => {
        navigate("/become-a-sponsor")
    }

    const handleTournamentRegistration = () => {
        setRegistrationType("youth")
        window.open(link3, "_blank");
    }

    return (
        <section className='baner-section'>
            {/* <div className='custom-container mt-lg-5 mt-3'>
                    <div
                        className="myFinance-widget"
                        data-ad-id="ebe461ff-6e71-4484-bf1c-03e43e225e01"
                        data-campaign="chameleon-comlink-zip-detected-providers"
                    ></div>
                </div> */}
            <div className='double-logos d-flex flex-column'>
                <div className='d-flex logo-holders'>
                    <img src={cash} />
                    <img className='ms-4' src={venmo} />
                </div>
                <div className='registration-btns-wraper d-flex'>
                    {/* <Dropdown className="d-flex align-items-center registration-btn">
                        <Dropdown.Toggle
                            variant="link"
                            id="dropdown-plans"
                        >
                            Click to Register
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                        <Dropdown.Item onClick={() => handleYouthRouteZorts()}>
                                Youth registration (ZORTS)
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleYouthRoute()}>
                                Youth Registration (NSID)
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleAdultForm()}>
                                Adult Registration
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    <Button className='bacome-sponsor-btn ms-xl-4 ms-lg-2 ms-1' onClick={() => handleTournamentRegistration()}>Tournament Registration
                        <span className='ms-4'>
                            <FontAwesomeIcon icon={faRightLong} />
                        </span>
                    </Button>
                    <Button className='bacome-sponsor-btn ms-xl-4 ms-lg-2 ms-1' onClick={() => handleSponsor()}>Become a sponsor
                        <span className='ms-4'>
                            <FontAwesomeIcon icon={faRightLong} />
                        </span>
                    </Button>
                </div>
            </div>

            <div className='sponsored-box'>
                <Link target="_blank" to="https://comlinkmobile.com/">
                    {/* <p>Sponsored by</p> */}
                    <img src={comlink} />
                </Link>
            </div>
            
        </section>
    )
}

export default Banner
