import React, { useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./SearchForm.css";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const intialValue = {
  firstname: "",
  lastname: "",
  teamName: "",
  homeTown: "",
  email: "",
  phone: "",
  division: "",
  paymentOption: "byCard",
  agreement: true,
  agreement2: true,
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "First name  must contain only letters and spaces"
    ),
  lastname: Yup.string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Last name  must contain only letters and spaces"
    ),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email is required"),
  teamName: Yup.string().required("Team name is required"),
  homeTown: Yup.string()
    .required("Home Town is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Full name  must contain only letters and spaces"
    ),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be digits")
    .length(14, "Phone number must be exactly 10 digits"),
  paymentOption: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .nullable(),
  agreement: Yup.boolean()
    .oneOf([true], "You must agree to the terms")
    .required("Agreement is required"),
  agreement2: Yup.boolean()
    .oneOf([true], "You must agree to the terms")
    .required("Agreement is required"),
  paymentOption: Yup.string()
    .required("Please select a payment option")
    .nullable(),
  division: Yup.string().required("Please select a division"),
});

const apiUrl = `${API_URL}/leads/create`;

const options = {
  style: {
    base: {
      iconColor: "#409131",
      // color: '#fff',
      height: "50px",
      fontWeight: "400",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      // ':-webkit-autofill': {
      //     color: '#fce883',
      // },
      "::placeholder": {
        color: "#212529",
      },
    },
    invalid: {
      iconColor: "#ff0101",
      color: "#ff0101",
    },
  },
};

const SearchForm = ({ section1Ref }) => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [enableStripe, setEnableStripe] = useState(true);
  const [flag, setFlag] = useState(true);
  const [cardError, setCardError] = useState("");
  const elements = useElements();
  const stripe = useStripe();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to "auto" for an instant scroll
    });
  }, []);

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      toast.dismiss();
      let token;
      if (
        values.paymentOption == "byCard" &&
        (!stripe || !elements || !flag)
      ) {
        setLoader(false);
        return;
      } else if (values.paymentOption == "byCard") {
        token = await stripe.createToken(elements.getElement(CardElement));
      }

      if (token?.error) {
        toast.error(token?.error?.message || "Something went wrong!");
        setLoader(false);
        console.error(token.error, "error");
        return;
      }

      if (token) {
        // if (values.division == "Adult Men's 7V7") {
        //   values.amount = 425 + 12.75;
        // } else {
        values.amount = 250 + 10.5;
        // }
        values.paymentMethod = "Card";
        values.token = token?.token?.id;
        // values.amount = 400;
        values.currency = "$";
      }
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({ ...values, source: "Adult-Reg" }),
      };
      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? "Team with this email is already registered"
                : response?.status == 406
                ? "Oops! It seems there was an issue processing your payment."
                : "Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          toast.success("Thanks for registering. Have a great day!");
          formik.resetForm();
          navigate("/");
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          toast.error("" + error);
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });

  const handlePaymentOptionChange = (event) => {
    formik.handleChange(event); // Let formik handle the change
    if (event.target.value === "byCard") {
      setEnableStripe(true);
    } else {
      setEnableStripe(false);
    }
  };

  const handleCardChange = (event) => {
    try {
      if (event.complete && !event.error) {
        console.log("in if");
        setFlag(true);
        setCardError("");
      } else {
        setFlag(false);
        setCardError("Please enter your card information.");
        setLoader(false);
        console.log("event.error.message", event.error.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="custom-container container">
      <div className="form_content_banner mt-xl-5 mt-3">
        <form onSubmit={formik.handleSubmit}>
          <h4 className="green-clr text-center mb-lg-4 mb-3" ref={section1Ref}>
            Adult Registration Form
          </h4>
          <hr></hr>
          <div className="row">
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Captain First name</label>
              <input
                className={`form-control ${
                  formik.touched.firstname && formik.errors.firstname
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="firstname"
                {...formik.getFieldProps("firstname")}
                placeholder="Enter your firstname"
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className="for-red">{formik.errors.firstname}</div>
              )}
            </div>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Captain Last name</label>
              <input
                className={`form-control ${
                  formik.touched.lastname && formik.errors.lastname
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="lastname"
                {...formik.getFieldProps("lastname")}
                placeholder="Enter your lastname"
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className="for-red">{formik.errors.lastname}</div>
              )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Team Name</label>
              <input
                className={`form-control ${
                  formik.touched.teamName && formik.errors.teamName
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="teamName"
                {...formik.getFieldProps("teamName")}
                placeholder="Enter your teamName"
              />
              {formik.touched.teamName && formik.errors.teamName && (
                <div className="for-red">{formik.errors.teamName}</div>
              )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                {...formik.getFieldProps("email")}
                className={`form-control ${
                  formik.touched.email && formik.errors.email
                    ? "is-invalid"
                    : ""
                }`}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="for-red">{formik.errors.email}</div>
              )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Phone Number</label>
              <ReactInputMask
                className={`form-control ${
                  formik.touched.phone && formik.errors.phone
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                mask="(999) 999-9999"
                {...formik.getFieldProps("phone")}
                placeholder="Enter your phone number"
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="for-red">{formik.errors.phone}</div>
              )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Team Home Town</label>
              <input
                className={`form-control ${
                  formik.touched.homeTown && formik.errors.homeTown
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="homeTown"
                {...formik.getFieldProps("homeTown")}
                placeholder="Enter your home town city"
              />
              {formik.touched.homeTown && formik.errors.homeTown && (
                <div className="for-red">{formik.errors.homeTown}</div>
              )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Division</label>
              <select
                name="division"
                className={`form-control ${
                  formik.touched.division && formik.errors.division
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("division")}
              >
                <option value="" selected hidden>
                  Please select the Division
                </option>
                {/* <option value="Adult Men's 7V7">Adult Men's 7V7</option> */}
                <option value="Adult Men's 5V5">Adult Men's 5V5</option>
                <option value="Adult Women's 5V5">Adult Women's 5V5</option>
                <option value="Adult COED 5V5">Adult COED 5V5</option>
              </select>
              {formik.touched.division && formik.errors.division && (
                <div className="for-red">{formik.errors.division}</div>
              )}
            </div>
          </div>
          {formik.values.division ? (
            <div>
              <p>
                Registration fee:{" "}
                <b>
                  $250
                  {/* {formik.values.division == "Adult Men's 7V7"
                    ? "$425"
                    : "$250"} */}
                </b>
              </p>
              <p>
                Admin Fee:{" "}
                <b>
                  $10.50
                  {/* {formik.values.division == "Adult Men's 7V7"
                    ? "$12.75"
                    : "$10.50"} */}
                </b>
              </p>
              <p>
                Total Due:{" "}
                <b>
                  $260.50
                  {/* {formik.values.division == "Adult Men's 7V7"
                    ? "$437.75"
                    : "$260.50"} */}
                </b>
              </p>
            </div>
          ) : null}
          <div className="form-group mb-4 select-sim-type mt-2">
            <div className="d-flex flex-sm-row flex-column">
              <label className="custom-control overflow-radio relative mb-3">
                <input
                  name="paymentOption"
                  className="overflow-control-input"
                  type="radio"
                  value="byCard"
                  onChange={handlePaymentOptionChange}
                  checked={formik.values.paymentOption == "byCard"}
                />
                <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                  <div className="Submitting_form box main-wrapper-tooltip">
                    <p className="pl-4">Pay by Card</p>
                  </div>
                </span>
              </label>
              <label className="custom-control overflow-radio relative ms-sm-3 mb-3">
                <input
                  name="paymentOption"
                  className="overflow-control-input"
                  type="radio"
                  value="cashApp"
                  onChange={handlePaymentOptionChange}
                  checked={formik.values.paymentOption == "cashApp"}
                />
                <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                  <div className="Submitting_form box main-wrapper-tooltip">
                    <p className="pl-4">Cash App</p>
                  </div>
                </span>
                <p></p>
              </label>
              <label className="custom-control overflow-radio relative ms-sm-3 mb-3">
                <input
                  name="paymentOption"
                  className="overflow-control-input"
                  type="radio"
                  value="venmo"
                  onChange={handlePaymentOptionChange}
                  checked={formik.values.paymentOption == "venmo"}
                />
                <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                  <div className="Submitting_form box main-wrapper-tooltip">
                    <p className="pl-4">Venmo</p>
                  </div>
                </span>
                <p></p>
              </label>
            </div>
            {formik.touched.paymentOption && formik.errors.paymentOption && (
              <div className="for-red">{formik.errors.paymentOption}</div>
            )}
            {formik.values.paymentOption != "" && enableStripe ? (
              <React.Fragment>
                <label className="mb-2">Enter your card information</label>
                <div className="col-lg-12">
                  <div className="stripe_field_wrap">
                    <CardElement
                      options={options}
                      onChange={(e) => handleCardChange(e)}
                    />
                    {cardError ? (
                      <p className="for-red mt-3">{cardError}</p>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            ) : formik.values.paymentOption == "venmo" ? (
              <div>
                Transfer amount to this Venmo: <b>@FFFL_Football</b>
              </div>
            ) : (
              <div>
                Transfer amount to this Cash App: <b>$FFFLFootball</b>
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="custom-control overflow-checkbox relative">
              <input
                name="agreement"
                className="overflow-control-input"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.agreement}
              />
              <span className="overflow-control-indicator "></span>
              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                <div className="Submitting_form box main-wrapper-tooltip">
                  <p className="pl-25">
                    I understand that my team is not full registered until my
                    payment has been made.
                  </p>
                </div>
              </span>
            </label>
          </div>
          {formik.touched.agreement && formik.errors.agreement && (
            <div className="for-red">{formik.errors.agreement}</div>
          )}
          <br />
          <div className="form-group">
            <label className="custom-control overflow-checkbox relative">
              <input
                name="agreement2"
                className="overflow-control-input"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.agreement2}
              />
              <span className="overflow-control-indicator "></span>
              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                <div className="Submitting_form box main-wrapper-tooltip">
                  <p className="pl-25">
                    By clicking the submit button above and submitting this
                    form, You acknowledge that you give NFFA/Florida Flag
                    Football consent to contact me at the number and/or email
                    address I have provided above with automated technology in
                    relation to this inquiry via phone, e-mail, or text message.
                    I understand that making a purchase is not required to
                    consent to receive communications from NFFA/Florida Flag
                    Football.
                  </p>
                </div>
              </span>
            </label>
          </div>
          {formik.touched.agreement2 && formik.errors.agreement2 && (
            <div className="for-red">{formik.errors.agreement2}</div>
          )}
          <br />
          {/* <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
          <div className="Submitting_form box main-wrapper-tooltip">
            <p style={{ textAlign: "center" }}>
              By clicking the submit button above and submitting this form, You
              acknowledge that you give NFFA/Florida Flag Football consent to
              contact me at the number and/or email address I have provided
              above with automated technology in relation to this inquiry via
              phone, e-mail, or text message. I understand that making a
              purchase is not required to consent to receive communications from
              NFFA/Florida Flag Football.
            </p>
          </div>
        </span> */}
          <div className="d-flex  justify-content-center mt-xl-4 mt-3 mb-xl-4 mb-3">
            {loader ? (
              <button disabled className="btn theme-btn d-flex ">
                <span className="ml_9"> Loading...</span>
                <div className="spinner"></div>
              </button>
            ) : (
              <button type="submit" className="btn theme-btn d-flex ">
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
