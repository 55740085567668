import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Images } from "../../../assets/assets";
import "./Header.css";
import { Container, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFootball } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
// import words1 from "../../../assets/word-files/NFFA Adult_5V5_NON-CONTACT_RULEBOOK.docx";
// import words2 from "../../../assets/word-files/NFFA AND FFFL RULEBOOK 7V7.doc";
// import words3 from "../../../assets/word-files/NFFA youth 5v5 Rulebook.docx";
import words1 from "../../../assets/word-files/NFFA Adult 5V5 Non Contact Rulebook.pdf";
import words2 from "../../../assets/word-files/NFFA youth 5v5 Rulebook update.pdf";
import words3 from "../../../assets/word-files/NFFA And FFFL 7V7 Non Contact Rulebook.pdf";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const openWordFileInNewTab = (file) => {
    // Open the Word file in a new tab
    window.open(file, "_blank");
  };

  return (
    <div>
      <header className="site-header">
        <Container className="custom-container">
          <Navbar expand="lg">
            <div className="d-flex justify-content-between align-items-center w-100">
              <Link to="/">
                <img
                  src={Images.siteLogo}
                  className="img-fluid site-main-logo"
                  alt="img"
                />
              </Link>
              {/* desktop nav */}
              <div
                id="basic-navbar-nav"
                className="pages-names desktop-nav d-flex align-items-center flex-fill justify-content-end"
              >
                <Nav className="ms-auto d-flex align-items-center">
                  <div className="haeder-phone d-flex align-items-center">
                    <a
                      className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 mail-setting mail-envlope"
                      href="https://www.zortssports.com/join/tournament/17028"
                      target="_blank"
                    >
                      <span className="me-2">
                        <FontAwesomeIcon icon={faFootball} />
                      </span>
                      <span className="opacity-1">Tournament Results</span>
                    </a>
                    <Dropdown className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 gray-clr">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                        />
                      </svg>

                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-plans"
                        className="rule-book"
                      >
                        Download Rulebooks
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="other_menu_wrap">
                        <Dropdown.Item
                          onClick={() => openWordFileInNewTab(words1)}
                        >
                          NFFA Adult 5V5 Non Contact Rulebook
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => openWordFileInNewTab(words2)}
                        >
                          NFFA Youth 5V5 Non Contact Rulebook
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => openWordFileInNewTab(words3)}
                        >
                          NFFA and FFFL 7V7 Non Contact Rulebook
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <a
                      className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 mail-setting mail-envlope"
                      href="mailto:info@nffa.football"
                    >
                      <span className="me-2">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <span className="opacity-1">info@nffa.football</span>
                    </a>
                    <a
                      className="d-flex align-items-center ms-xl-3 ms-lg-2 ms-1 gray-clr"
                      href="tel:+12398391588"
                    >
                      <span className="call-lottie mail-setting custom-phone-svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                          />
                        </svg>
                      </span>
                      <span className="mobile-nbr">239 839-1588</span>
                    </a>
                  </div>
                </Nav>
              </div>
              {/* mobile nav */}
              <div className="mobile-menu-bar">
                <Button className="menu-burger-btn" onClick={handleShow}>
                  <span className="meun-burger"><FontAwesomeIcon icon={faBars} /></span>
                </Button>
                <Offcanvas show={show} onHide={handleClose} className="side-bar-menu">
                  <Offcanvas.Header closeButton></Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="haeder-phone d-flex flex-column  mobile-nav">
                      <a
                        className="d-flex align-items-center"
                        href="tel:+12398391588"
                      >
                        <span className="call-lottie mail-setting custom-phone-svg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#fff"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                            />
                          </svg>
                        </span>
                        <span className="mobile-nbr">
                          239 839-1588
                        </span>
                      </a>
                      <a
                        className="d-flex align-items-center mobile-enelope"
                        href="mailto:info@nffa.football"
                      >
                        <span className="call-lottie mail-setting ">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                        <span className="opacity-1">
                          info@nffa.football
                        </span>
                      </a>
                      <a
                        className="d-flex align-items-center mobile-enelope"
                        href="https://www.zortssports.com/post/clubHouse?tid=16151"
                        target="_blank"
                      >
                        <span className="call-lottie mail-setting ">
                          <FontAwesomeIcon icon={faFootball} />
                        </span>
                        <span className="opacity-1">
                          Tournament Results
                        </span>
                      </a>
                      <div className="d-flex flex-column  gray-clr download-rulebook-mobile">
                        <div className="d-flex download-rulebook-mobile-heading">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#fff"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                            />
                          </svg>
                          Download Rulebooks
                        </div>
                        <ul className="other_menu_wrap">
                          <li
                            onClick={() => openWordFileInNewTab(words1)}
                          >
                            NFFA Adult 5V5 Non Contact Rulebook
                          </li>
                          <li
                            onClick={() => openWordFileInNewTab(words2)}
                          >
                            NFFA Youth 5V5 Non Contact Rulebook
                          </li>
                          <li
                            onClick={() => openWordFileInNewTab(words3)}
                          >
                            NFFA and FFFL 7V7 Non Contact Rulebook
                          </li>
                        </ul>
                      </div>

                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
                {/* end moblie nav*/}
              </div>
            </div>
          </Navbar>
        </Container>
      </header>
    </div>
  );
};

export default Header;
