import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

function Modal() {
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const onConfirm = () => {
    // Handle confirm logic here
    setShow(false);
  };

  const onCancel = () => {
    // Handle cancel logic here
    setShow(false);
  };

  return (
    <div>
      <SweetAlert
        show={show}
        title={'Thanks for apply for sponsorship'}
        titleCssClass="custom-title"
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnText="Continue to Payment"
        confirmBtnCssClass="custom-confirm-button"
      >
        {(renderProps) => (
          <form>
            <p>u can add here any thing else</p>
          </form>
        )}
      </SweetAlert>

      {/* Button to trigger the modal */}
      <button className='bacome-sponsor-btn btn' onClick={() => setShow(true)}>Open Modal</button>
    </div>
  );
}

export default Modal;
