import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Images } from "../../../assets/assets";
import "./Sponsor.css";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Modal from "../../../components/main/Modal/Modal";
import SweetAlert from "react-bootstrap-sweetalert";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const intialValue = {
  firstname: "",
  lastname: "",
  companyWebsite: "",
  compnayName: "",
  email: "",
  phone: "",
  selectedPackage: "",
  comments: "",
  //   paymentOption: "byCard",
  //   agreement: true,
  //   agreement2: true,
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "First name  must contain only letters and spaces"
    ),
  lastname: Yup.string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Last name  must contain only letters and spaces"
    ),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email is required")
    .matches(/@.*\..+$/, "Enter a valid email address"),
  companyWebsite: Yup.string()
    .nullable()
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-]+)*\/?$/,
      "Invalid website format"
    ),

  compnayName: Yup.string().required("Compnay Name is required"),
  // .matches(
  //   /^[A-Za-z\s]+$/,
  //   "Full name  must contain only letters and spaces"
  // ),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be digits")
    .length(14, "Phone number must be exactly 10 digits"),
  //   paymentOption: Yup.string()
  //     .email("Invalid email address")
  //     .required("Email is required")
  //     .nullable(),
  //   agreement: Yup.boolean()
  //     .oneOf([true], "You must agree to the terms")
  //     .required("Agreement is required"),
  //   agreement2: Yup.boolean()
  //     .oneOf([true], "You must agree to the terms")
  //     .required("Agreement is required"),
  //   paymentOption: Yup.string()
  //     .required("Please select a payment option")
  //     .nullable(),
  selectedPackage: Yup.string().required(
    "Please select a package given above."
  ),
  comments: Yup.string().nullable(),
});

const apiUrl = `${API_URL}/leads/create`;
const apiUrlForPayment = `${API_URL}/leads/get-payment`;

const options = {
  style: {
    base: {
      iconColor: "#409131",
      // color: '#fff',
      height: "50px",
      fontWeight: "400",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      // ':-webkit-autofill': {
      //     color: '#fce883',
      // },
      "::placeholder": {
        color: "#212529",
      },
    },
    invalid: {
      iconColor: "#ff0101",
      color: "#ff0101",
    },
  },
};

const Sponsor = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [enableStripe, setEnableStripe] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [sponsorPayment, setSponsorPayment] = useState(0);
  const [flag, setFlag] = useState(true);
  const [cardError, setCardError] = useState("");
  const elements = useElements();
  const stripe = useStripe();
  const sectionForm = useRef(null);
  const sectionPayment = useRef(null);
  const [show, setShow] = useState(false);

  const onConfirm = () => {
    setShow(false);
    scrollToDiv("paymentSection");
    scrollToSection(sectionPayment);
  };

  const onCancel = () => {
    setShow(false);
  };

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to "auto" for an instant scroll
    });
  }, []);

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (enableStripe) {
        return;
      }
      setLoader(true);
      toast.dismiss();

      if (values?.selectedPackage == "Bronze Sponsor Package") {
        setSponsorPayment(600);
      } else if (values?.selectedPackage == "Silver Sponsor Package") {
        setSponsorPayment(999);
      } else if (values?.selectedPackage == "Gold Sponsor Package") {
        setSponsorPayment(2000);
      } else if (values?.selectedPackage == "Platinum Sponsor Package") {
        setSponsorPayment(5000);
      }

      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({ ...values, source: "Sponsor" }),
      };
      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? "This email is already registered"
                : response?.status == 406
                ? "Oops! It seems there was an issue processing your payment."
                : "Oops! Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          toast.success(
            "Thanks for applying for becoming sponsor. Have a great day!"
          );
          setLeadId(data?.data?.leadId);
          setEnableStripe(true);
          setDisabled(true);
          setShow(true);
          //   scrollToDiv("paymentSection");
          //   scrollToSection(sectionPayment);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          toast.error("" + error);
          console.error("Error:", error);
        })
        .finally(() => {
          setLoader(false);
        });
    },
  });

  const handleCardChange = (event) => {
    try {
      if (event.complete && !event.error) {
        console.log("in if");
        setFlag(true);
        setCardError("");
      } else {
        setFlag(false);
        setCardError("Please enter your card information.");
        setLoader(false);
        console.log("event.error.message", event.error.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePackage = (item) => {
    formik.setFieldValue("selectedPackage", item);
    scrollToSection(sectionForm);
  };

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const makePayment = async () => {
    let payload = {};
    setLoader(true);
    toast.dismiss();
    let token;
    if (!stripe || !elements || !flag) {
      setLoader(false);
      return;
    } else {
      token = await stripe.createToken(elements.getElement(CardElement));
    }

    if (token?.error) {
      toast.error(token?.error?.message || "Something went wrong!");
      setLoader(false);
      console.error(token.error, "error");
      return;
    }
    if (token?.token) {
      payload.paymentMethod = "Card";
      payload.token = token?.token?.id;
      payload.amount = sponsorPayment;
      payload.currency = "$";
    }
    if (leadId) {
      payload.leadId = leadId;
    }
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({ ...payload, source: "Sponsor" }),
    };
    fetch(apiUrlForPayment, requestOptions)
      .then((response) => {
        if (!response?.ok) {
          throw new Error(
            response?.status == 429
              ? "Too many requests from this IP, please try again after a day"
              : response?.status == 409
              ? "This email is already registered"
              : response?.status == 406
              ? "Oops! It seems there was an issue processing your payment."
              : "Oops! Something went wrong"
          );
        }
        return response?.json(); // Parse the response body as JSON
      })
      .then((data) => {
        toast.success(
          "Thanks for making payment for becoming sponsor. Have a great day!"
        );
        setDisabled(false);
        navigate("/");
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        toast.error("" + error);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const scrollToDiv = (divId) => {
    var targetDiv = document.getElementById(divId);

    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("Element with ID " + divId + " not found.");
    }
  };

  return (
    <div className="become-sponsor-pg mt-xl-5 mt-3 mb-xl-5 mb-3">
      {/* <Modal /> */}
      <SweetAlert
        show={show}
        title={"Appreciating your interest!"}
        titleCssClass="custom-title"
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnText="Continue"
        confirmBtnCssClass="custom-confirm-button"
      >
        {(renderProps) => (
          <form>
            <p className="sponsor-model-text">
              Click on <b>Continue</b> to making a payment.
            </p>
          </form>
        )}
      </SweetAlert>
      <div className="custom-container container">
        <div className="mb-5">
          <div className="section-heading">
            <h4 className="theme-clr text-center">BECOME A SPONSOR</h4>
            <p className="text-center mb-lg-4 mb-3">
              Select a package here and become a sponsor of Nffa Football
            </p>
          </div>
          <div className="sponsorCard-wrap">
            <div className="d-flex justify-content-center flex-wrap ">
              <div
                className="sponsor-card mx-2 mb-4"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sponsor-header">
                  <div className="sponsor-image">
                    <img
                      src={Images.siteLogo}
                      className="img-fluid site-main-logo"
                      alt="logo"
                    />
                  </div>
                  <span className="sponsor-title">
                    Starter Package: <b>$100</b>
                  </span>
                </div>
                <div className="sponsor-content sponsor-content-body">
                  <p className="sponsor-message">
                    <ul>
                      <li>Logo Placement: Company logo on the NFFA website.</li>
                      <li>
                        Social Media Recognition: Mention and thank you on social
                        media platforms.
                      </li>
                      {/* <li>
                        Acknowledgment: Verbal acknowledgment during events.
                      </li>
                      <li>Company Banner: Display a small banner at events.</li>
                      <li>
                        One Complimentary Team Registration: Opportunity to
                        register one team for free.
                      </li>
                      <li>Sponsor plaque</li> */}
                    </ul>
                  </p>
                </div>
                <div className="sponsor-actions" style={{ marginTop: "auto" }}>
                  <button
                    className="sponsor-history"
                    onClick={() => handlePackage("Bronze Sponsor Package")}
                    type="button"
                  >
                    Select
                  </button>
                </div>
              </div>
              <div
                className="sponsor-card mx-2 mb-4"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sponsor-header">
                  <div className="sponsor-image">
                    <img
                      src={Images.siteLogo}
                      className="img-fluid site-main-logo"
                      alt="logo"
                    />
                  </div>
                  <span className="sponsor-title">
                    Starter Plus Package: <b>$300</b>
                  </span>
                </div>
                <div className="sponsor-content sponsor-content-body">
                  <p className="sponsor-message">
                    <ul>
                      <li>Logo Placement: Company logo on the NFFA website.</li>
                      <li>
                        Social Media Recognition: Mention and thank you on social
                        media platforms.
                      </li>
                      <li>
                        Acknowledgment: Verbal acknowledgment during events.
                      </li>
                      <li>Company Banner: Display a small banner at events.</li>
                      {/* <li>
                        One Complimentary Team Registration: Opportunity to
                        register one team for free.
                      </li>
                      <li>Sponsor plaque</li> */}
                    </ul>
                  </p>
                </div>
                <div className="sponsor-actions" style={{ marginTop: "auto" }}>
                  <button
                    className="sponsor-history"
                    onClick={() => handlePackage("Bronze Sponsor Package")}
                    type="button"
                  >
                    Select
                  </button>
                </div>
              </div>
              <div
                className="sponsor-card mx-2 mb-4"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sponsor-header">
                  <div className="sponsor-image">
                    <img
                      src={Images.siteLogo}
                      className="img-fluid site-main-logo"
                      alt="logo"
                    />
                  </div>
                  <span className="sponsor-title">
                    Bronze Sponsor Package: <b>$600</b>
                  </span>
                </div>
                <div className="sponsor-content sponsor-content-body">
                  <p className="sponsor-message">
                    <ul>
                      <li>Logo Placement: Company logo on the NFFA website.</li>
                      <li>
                        Social Media Recognition: Mention and thank you on social
                        media platforms.
                      </li>
                      <li>
                        Acknowledgment: Verbal acknowledgment during events.
                      </li>
                      <li>Company Banner: Display a small banner at events.</li>
                      <li>
                        One Complimentary Team Registration: Opportunity to
                        register one team for free.
                      </li>
                      <li>Sponsor plaque</li>
                    </ul>
                  </p>
                </div>
                <div className="sponsor-actions" style={{ marginTop: "auto" }}>
                  <button
                    className="sponsor-history"
                    onClick={() => handlePackage("Bronze Sponsor Package")}
                    type="button"
                  >
                    Select
                  </button>
                </div>
              </div>
              <div
                className="sponsor-card mx-2 mb-4"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sponsor-header">
                  <div className="sponsor-image">
                    <img
                      src={Images.siteLogo}
                      className="img-fluid site-main-logo"
                      alt="logo"
                    />
                  </div>
                  <span className="sponsor-title">
                    Silver Sponsor Package: <b>$999</b>
                  </span>
                </div>
                <div className="sponsor-content sponsor-content-body">
                  <p className="sponsor-message">
                    <ul>
                      <li>All Bronze Package Benefits.</li>
                      <li>
                        Enhanced Logo Placement: Larger company logo on the
                        website and promotional materials.
                      </li>
                      <li>
                        Social Media Shoutouts: Regular shoutouts and tagged
                        mentions on social media.
                      </li>
                      <li>
                        Booth Space: A designated space for a promotional booth at
                        events.
                      </li>
                      <li>
                        Complimentary Team Registration: Opportunity to register
                        one team for free.
                      </li>
                      <li>
                        Event Program Recognition: Company mentions in event
                        programs and live feeds or broadcasts.
                      </li>
                      <li>Sponsor plaque</li>
                    </ul>
                  </p>
                </div>
                <div className="sponsor-actions" style={{ marginTop: "auto" }}>
                  <button
                    className="sponsor-history"
                    type="button"
                    onClick={() => handlePackage("Silver Sponsor Package")}
                  >
                    Select
                  </button>
                </div>
              </div>
              <div
                className="sponsor-card mx-2 mb-4"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sponsor-header">
                  <div className="sponsor-image">
                    <img
                      src={Images.siteLogo}
                      className="img-fluid site-main-logo"
                      alt="logo"
                    />
                  </div>
                  <span className="sponsor-title">
                    Gold Sponsor Package: <b>$2000</b>
                  </span>
                </div>
                <div className="sponsor-content sponsor-content-body">
                  <p className="sponsor-message">
                    <ul>
                      <li>All Silver Package Benefits.</li>
                      <li>
                        Prime Logo Placement: Prominent placement on the
                        organization's website, newsletters, and event signage.
                      </li>
                      <li>
                        Exclusive Social Media Campaign: A dedicated social media
                        campaign highlighting the sponsor.
                      </li>
                      <li>
                        VIP Hospitality: Access to a VIP hospitality area during
                        events.
                      </li>
                      <li>
                        Two Complimentary Team Registrations: Opportunity to
                        register two teams for free.
                      </li>
                      <li>
                        Recognition in Press Releases: Inclusion in press releases
                        related to the event.
                      </li>
                      <li>Sponsor plaque.</li>
                    </ul>
                  </p>
                </div>
                <div className="sponsor-actions" style={{ marginTop: "auto" }}>
                  <button
                    className="sponsor-history"
                    onClick={() => handlePackage("Gold Sponsor Package")}
                    type="button"
                  >
                    Select
                  </button>
                </div>
              </div>
              <div
                className="sponsor-card mx-2 mb-4"
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="sponsor-header">
                  <div className="sponsor-image">
                    <img
                      src={Images.siteLogo}
                      className="img-fluid site-main-logo"
                      alt="logo"
                    />
                  </div>
                  <span className="sponsor-title">
                    Platinum Sponsor Package: <b>$5000</b>
                  </span>
                </div>
                <div className="sponsor-content sponsor-content-body">
                  <p className="sponsor-message">
                    <li>All Gold Package Benefits.</li>
                    <li>
                      Title Sponsorship: Exclusive title sponsorship of the league
                      or a major event.
                    </li>
                    <li>
                      Main Event Naming Rights: The opportunity to name a flagship
                      event.
                    </li>
                    <li>
                      Premium Logo Placement: Featured on all promotional
                      materials, championship shirts, and merchandise.
                    </li>
                    <li>
                      Dedicated Email Campaign: A dedicated email & sms campaign
                      to the NFFA subscriber base.
                    </li>
                    <li>
                      Five Complimentary Team Registrations: Opportunity to
                      register five teams for free.
                    </li>
                    <li>
                      Speaking Opportunities: The chance to address the audience
                      during key events.
                    </li>
                    <li>
                      Exclusive Product/Service Showcase: Exclusive display or
                      demonstration area for products/services.
                    </li>
                    <li>Sponsor plaque.</li>
                  </p>
                </div>
                <div className="sponsor-actions">
                  <button
                    className="sponsor-history"
                    style={{ marginTop: "auto" }}
                    onClick={() => handlePackage("Platinum Sponsor Package")}
                    type="button"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>  
        </div>
        <form onSubmit={formik.handleSubmit} className="form_content_banner">
          <h5 className="green-clr text-center mb-lg-4 mb-3">
            Want to sponsor? Fill out the form below!
          </h5>
          <hr></hr>
          <div className="row" ref={sectionForm}>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>First name</label>
              <span className="for-red">*</span>
              <input
                className={`form-control ${
                  formik.touched.firstname && formik.errors.firstname
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                disabled={disabled}
                name="firstname"
                {...formik.getFieldProps("firstname")}
                placeholder="Enter your firstname"
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className="for-red">{formik.errors.firstname}</div>
              )}
            </div>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Last name</label>
              <span className="for-red">*</span>
              <input
                className={`form-control ${
                  formik.touched.lastname && formik.errors.lastname
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                disabled={disabled}
                name="lastname"
                {...formik.getFieldProps("lastname")}
                placeholder="Enter your lastname"
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className="for-red">{formik.errors.lastname}</div>
              )}
            </div>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Email</label>
              <span className="for-red">*</span>
              <input
                type="email"
                disabled={disabled}
                placeholder="Enter your email"
                {...formik.getFieldProps("email")}
                className={`form-control ${
                  formik.touched.email && formik.errors.email
                    ? "is-invalid"
                    : ""
                }`}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="for-red">{formik.errors.email}</div>
              )}
            </div>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Phone Number</label>
              <span className="for-red">*</span>
              <ReactInputMask
                className={`form-control ${
                  formik.touched.phone && formik.errors.phone
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                disabled={disabled}
                mask="(999) 999-9999"
                {...formik.getFieldProps("phone")}
                placeholder="Enter your phone number"
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="for-red">{formik.errors.phone}</div>
              )}
            </div>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Company name</label>
              <span className="for-red">*</span>
              <input
                className={`form-control ${
                  formik.touched.compnayName && formik.errors.compnayName
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="compnayName"
                disabled={disabled}
                {...formik.getFieldProps("compnayName")}
                placeholder="Enter your Compnay Name"
              />
              {formik.touched.compnayName && formik.errors.compnayName && (
                <div className="for-red">{formik.errors.compnayName}</div>
              )}
            </div>
            <div className="form-group col-lg-6 mb-xl-3 mb-lg-2 mb-2">
              <label>Company Website</label>
              {/* <span className="for-red">*</span> */}
              <input
                className={`form-control ${
                  formik.touched.companyWebsite && formik.errors.companyWebsite
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="companyWebsite"
                disabled={disabled}
                {...formik.getFieldProps("companyWebsite")}
                placeholder="Enter your Company Website"
              />
              {formik.touched.companyWebsite &&
                formik.errors.companyWebsite && (
                  <div className="for-red">{formik.errors.companyWebsite}</div>
                )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Selected Package</label>
              <input
                className={`form-control ${
                  formik.touched.selectedPackage &&
                  formik.errors.selectedPackage
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                disabled
                name="selectedPackage"
                {...formik.getFieldProps("selectedPackage")}
                placeholder="Enter your Budget Range"
              />
              {formik.touched.selectedPackage &&
                formik.errors.selectedPackage && (
                  <div className="for-red">{formik.errors.selectedPackage}</div>
                )}
            </div>
            <div className="form-group col-lg-12 mb-xl-3 mb-lg-2 mb-2">
              <label>Any Questions or Comments?</label>
              <textarea
                className={`form-control ${
                  formik.touched.comments && formik.errors.comments
                    ? "is-invalid"
                    : ""
                }`}
                disabled={disabled}
                type="text"
                name="comments"
                {...formik.getFieldProps("comments")}
                placeholder="Any question or comments"
              ></textarea>
              {formik.touched.comments && formik.errors.comments && (
                <div className="for-red">{formik.errors.comments}</div>
              )}
            </div>
            {enableStripe ? (
              <div>
                <p>
                  Registration fee for your selected package is{" "}
                  <b className="sponsor-payment-text">${sponsorPayment}</b>
                </p>
              </div>
            ) : null}
            <div
              className="form-group mb-4 select-sim-type mt-2"
              ref={sectionPayment}
              id="paymentSection"
            >
              {enableStripe ? (
                <React.Fragment>
                  <label className="mb-2">
                    <b>Enter your card information</b>
                  </label>
                  <div className="col-lg-12">
                    <div className="stripe_field_wrap">
                      <CardElement
                        options={options}
                        onChange={(e) => handleCardChange(e)}
                      />
                      {cardError ? (
                        <p className="for-red mt-3">{cardError}</p>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          {/* <div className="form-group">
            <label className="custom-control overfol ol6oolow-checkbox relative">
              <input
                name="agreement"
                className="overflow-control-input"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.agreement}
              />
              <span className="overflow-control-indicator "></span>
              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                <div className="Submitting_form box main-wrapper-tooltip">
                  <p className="pl-25">
                    I understand that my team is not full registered until my
                    payment has been made.
                  </p>
                </div>
              </span>
            </label>
          </div>
          {formik.touched.agreement && formik.errors.agreement && (
            <div className="for-red">{formik.errors.agreement}</div>
          )}
          <br />
          <div className="form-group">
            <label className="custom-control overflow-checkbox relative">
              <input
                name="agreement2"
                className="overflow-control-input"
                type="checkbox"
                onChange={formik.handleChange}
                checked={formik.values.agreement2}
              />
              <span className="overflow-control-indicator "></span>
              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                <div className="Submitting_form box main-wrapper-tooltip">
                  <p className="pl-25">
                    By clicking the submit button above and submitting this form,
                    You acknowledge that you give NFFA/Florida Flag Football
                    consent to contact me at the number and/or email address I
                    have provided above with automated technology in relation to
                    this inquiry via phone, e-mail, or text message. I understand
                    that making a purchase is not required to consent to receive
                    communications from NFFA/Florida Flag Football.
                  </p>
                </div>
              </span>
            </label>
          </div>
          {formik.touched.agreement2 && formik.errors.agreement2 && (
            <div className="for-red">{formik.errors.agreement2}</div>
          )}
          <br /> */}
          {/* <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
              <div className="Submitting_form box main-wrapper-tooltip">
                <p style={{ textAlign: "center" }}>
                  By clicking the submit button above and submitting this form, You
                  acknowledge that you give NFFA/Florida Flag Football consent to
                  contact me at the number and/or email address I have provided
                  above with automated technology in relation to this inquiry via
                  phone, e-mail, or text message. I understand that making a
                  purchase is not required to consent to receive communications from
                  NFFA/Florida Flag Football.
                </p>
              </div>
            </span> */}
          {!enableStripe ? (
            <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
              {loader ? (
                <button disabled className="btn theme-btn d-flex ">
                  <span className="ml_9"> Loading...</span>
                  <div className="spinner"></div>
                </button>
              ) : (
                <button type="submit" className="btn theme-btn d-flex ">
                  Submit
                </button>
              )}
            </div>
          ) : (
            <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
              {loader ? (
                <button disabled className="btn theme-btn d-flex ">
                  <span className="ml_9"> Loading...</span>
                  <div className="spinner"></div>
                </button>
              ) : (
                <button
                  onClick={() => makePayment()}
                  className="btn theme-btn d-flex "
                >
                  Proceed
                </button>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Sponsor;
