import React, { useEffect, useState, useRef } from 'react'
import Banner from './Banner.js/Banner'
import NffaForm from './Form/NffaForm';

function Home() {
	const [registrationType, setRegistrationType] = useState("");
	const section1Ref = useRef(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Adds smooth scrolling animation
		});
	}, [])

	const scrollToSection = (ref) => {
		if (ref && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			});
		}
	};

	return (
		<div className='homepg-wraper'>
			<Banner setRegistrationType={setRegistrationType} scrollToSection={scrollToSection} section1Ref={section1Ref}/>
			{/* {registrationType == "adult" ? <NffaForm section1Ref={section1Ref}/> : null} */}
		</div>
	)
}

export default Home
