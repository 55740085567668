import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/layout/Layout';
import Home from './components/main/Home/Home';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Sponsor from './components/main/Sponsor/Sponsor';
import SearchForm from './components/main/Common/SearchForm/SearchForm';
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(stripePublicKey);

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <Routes>
          {/* Guest routes here */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Elements stripe={stripePromise}>
              <Home />
            </Elements>} />
            <Route path="become-a-sponsor" element={<Elements stripe={stripePromise}>
              <Sponsor />
            </Elements>} />
            <Route path="adult-registration" element={<Elements stripe={stripePromise}>
              <SearchForm />
            </Elements>} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
